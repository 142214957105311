// import React from 'react';
// import { Outlet, Navigate} from "react-router-dom";

// const Rutaprotegida = ({
//     canActivate,
//     redirecPath= "/"
// }) => {

//     if (!canActivate) {

//         return <Navigate to={redirecPath} replace/>

//     }

//         return <Outlet/>;


// }

// export default Rutaprotegida;
import { Navigate, Outlet } from 'react-router-dom';

const Rutaprotegida = ({ canActivate }) => {
    const user = localStorage.getItem('user');  // Verifica si el usuario está autenticado

    // Si no hay usuario, redirige al login
    return user ? <Outlet /> : <Navigate to="/login" />;
};

export default Rutaprotegida;
