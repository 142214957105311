import React from 'react';
import  AppRouter  from './auth/AppRouter.js';

function App() {
  return (
    <div>
      <AppRouter/>
    </div>
  )
};

export default App;
