import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocalStorage } from 'react-use';
import { API1 } from './api';
import { encodedCredentials50 } from './credentials';

const useApiData = () => {
    const [sesenta, setSesenta] = useState([]);
    const [veinte, setVeinte] = useState([]);
    const [divisa, setDivisa] = useState("");
    const [user, setUser] = useLocalStorage("user");
    const [wallet, setWallet] = useState(0);
    const [investmentTotal, setInvestmentTotal] = useState(0);
    const [earningsTotal, setEarningsTotal] = useState(0);
    const [withdrawalsTotal, setWithdrawalsTotal] = useState(0);
    const [withdrawals, setWithdrawals] = useState([]);
    const [invesment, setInvesment] = useState([]);
    const [payment, setPayment] = useState([]);
    const [userName, setUserName] = useState("");

    const formData = {
        user: user
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        const fetchData = async () => {
            try {
                const response = await axios.post(`${API1}inicio`, formData, {
                    headers: {
                        'Authorization': `Basic ${encodedCredentials50}`,
                    },
                });
                const data = response.data;
                setSesenta(data.investment_time12);
                setVeinte(data.investment_time6);
                setDivisa(data.investment_type);
                setWallet(data.total_wallet || 0);
                setInvestmentTotal(data.total_investment || 0);
                setEarningsTotal(data.profit_currency || 0);
                setWithdrawalsTotal(data.total_withdrawal || 0);
                setWithdrawals(data.withdrawal);
                setInvesment(data.investment);
                setPayment(data.paymentdata);
                setUserName(data.name);
                setDivisa(data.investment_type);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user]); 

    return {
        sesenta, veinte, divisa, wallet, investmentTotal, earningsTotal, withdrawalsTotal, withdrawals, invesment, payment, userName
    };
};

export default useApiData;
