import React, { createContext, useContext } from 'react';
import useApiData from './apiInicio';  // Tu hook personalizado

// Crear el contexto
const ApiDataContext = createContext();

// Proveedor del contexto
export const ApiDataProvider = ({ children }) => {
    const apiData = useApiData();  // Llamar una sola vez al hook para obtener los datos
    return (
        <ApiDataContext.Provider value={apiData}>
            {children}
        </ApiDataContext.Provider>
    );
};

// Hook para consumir el contexto
export const useApiDataContext = () => {
    return useContext(ApiDataContext);
};
