import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import styles from "./Register.module.css";
import axios from 'axios';
import { FaArrowCircleLeft } from "react-icons/fa";
import { API2 } from '../constants/api';
import { encodedCredentials51 } from '../constants/credentials';

const Register = () => {
 
  const [isLoading, setIsLoading] = useState(false); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

  const fechaActual = new Date();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [regionTel, setRegionTel] = useState("+52");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [user, setUser] = useState("");
  const [reference, setReference] = useState("");
  const [pass, setPass] = useState("");
  const [passRepeat, setPassRepeat] = useState("");
  // const [userModify, setUserModify] = useState("");
  const [selectedFileNamee, setSelectedFileNamee] = useState('Select file:');
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(0);
  const [gender, setGender] = useState("Man");
  const [country, setCountry] = useState("MEX");

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!name || !lastName || !email || !tel || !birthDate || !user || !pass) {
      setErrorMessage("Please complete all required fields.");
      return;
    }
    if (pass.length < 8) {
      setErrorMessage("The password must be at least 8 characters.");
     } else {
    setErrorMessage(""); 
    }
    if (user.length < 6) {
      setErrorMessage("User must be at least 6 characters.");
     } else {
    setErrorMessage(""); 
    }
    if (!/(?=.*[A-Z])(?=.*\d)/.test(pass)) {
      setErrorMessage("The password must contain at least one capital letter and one number.");
      } else {
    setErrorMessage(""); 
    }
    if (!/(?=.*[A-Z])(?=.*\d)/.test(user)) {
      setErrorMessage("Username must contain at least one capital letter and one number.");
       } else {
    setErrorMessage(""); 
    }
    if (pass !== passRepeat){
      setErrorMessage(
        "Passwords do not match");
     } else {
    setErrorMessage(""); 
    }
    if (pass === user) {
      setErrorMessage("Username and password cannot be the same.");
      return;
    } else {
      setErrorMessage(""); 
    }

    setIsLoading(true);
    setIsButtonDisabled(true);


    const formData = new FormData(); 
    const data = {
      name: name,
      last_name: lastName,
      gender : gender,
      region_tel: regionTel,
      tel: tel,
      email: email,
      birth_date: birthDate,
      user: user,
      pass: pass,
      country : country,
      reference: reference,
      create_date: fechaActual,
      flag_evidence: value,
      user_modify: "pagina web",
    };
    

    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];
     
    if (!file) {
      // console.error('Selecciona un archivo para subir.');
      Swal.fire({
        title: 'Error',
        text:'The file is missing as an attachment.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return; 
    }

    
    formData.append('file', file, file.name);
    formData.append('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));

   {
    const response = await axios.post(`${API2}tempuser`, formData, {
      headers: {
        'Authorization': `Basic ${encodedCredentials51}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      if (response.data){
        const dataemail = {
          "to": email,
          "subject": "prueba de correo electrónico2",
          "name": name,
        }
        
      {
        axios.post(`${API2}send-email`, dataemail, {
         headers: {
           'Authorization': `Basic ${encodedCredentials51}`,
           'Content-Type': 'application/json'
         }
       })
       .then((response) => {
        
         })
         .catch((error) => {
           console.error('Error:', error.response);
           Swal.fire({
             title: 'Error',
             text:error.response.data.error || 'There was an error processing the request.',
             icon: 'error',
             confirmButtonText: 'OK'
           });
         });
        }
     };
        
        Swal.fire({
          title: 'Success',
          text: 'Your data will be evaluated, please check your email./Tus datos serán evaluados, por favor checa tu correo.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = 'https://mexicolindoyqueridoproduce.com/';
          }
        
        setIsLoading(false);
        setIsButtonDisabled(false);
      })
    })
      .catch((error) => {
        
        Swal.fire({
          title: 'Error',
          text: error.response.data.error || 'There was an error processing the request.',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        setIsLoading(false);
        setIsButtonDisabled(false);
      });
      });
    
  };
}

  
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileNamee("Selected file: " + file.name);
    setValue(1);
  };

  return (
    <div>
      <div className="container">
      <a href="https://mexicolindoyqueridoproduce.com/">
        <FaArrowCircleLeft  style={{ fontSize: '30px', color: 'blue' }} />
        Home/Inicio
        </a>
        <div className="card o-hidden border-0  shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <img className="img-fluid px-3 px-sm-4 mt-3" style={{ width: '35rem', height: '35rem' }} src="img/register.jpg" alt="forgot"></img>
              </div>

              <div className="col-lg-6">
                <div className="p-3">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                  </div>
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <input type="text" className="form-control form-control-user" id="exampleFirstName" placeholder="Name / Nombre"
                          value={name} onChange={(e) => setName(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-user" id="exampleLastName" placeholder="Last Name / Apellido"
                          value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <input type="email" required className="form-control form-control-user" id="exampleInputEmail" placeholder="Email"
                        value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                      <select className={styles.formControl} name="gender"
                          value={gender} onChange={(e) => setGender(e.target.value)}>
                          <option value="Man">Man / Hombre</option>
                          <option value="Woman">Woman / Mujer</option>
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <select type="text" className={styles.formControl} id="exampleCountry"
                          value={country} onChange={(e) => setCountry(e.target.value)} >
                          <option value="MEX">México</option>
                          <option value="USA">United States(USA)</option>
                         </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <select className={styles.formControl} name="regionTel"
                          value={regionTel} onChange={(e) => setRegionTel(e.target.value)}>
                          <option value="+52">(+52)</option>
                          <option value="+1">(+1)</option>
                        </select>
                      </div>
                      <div className="col-sm-9">
                        <input type="text" className="form-control form-control-user" id="examplePhoneNumber" placeholder="Phone number / Télefono"
                          value={tel} onChange={(e) => setTel(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <h6 className="h6 text-gray-900">Birthdate:</h6>
                        <input type="date" className="form-control form-control-user" id="exampleBirthdate"
                          value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                        <p className="h6 text-gray-900">Evidence of Invesment:</p>
                        <label htmlFor="fileInput" className="btn btn-primary btn-user btn-block" >
                          <input type="file" id="fileInput" className="d-none" name="fileInput" onChange={handleFileChange}/>
                          {selectedFileNamee && <span>{selectedFileNamee}</span>}
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-user" id="exampleUser" placeholder="User / Usuario"
                          value={user} onChange={(e) => setUser(e.target.value)} />
                      </div>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-user" id="exampleReference"
                          placeholder="Advisor / Asesor"
                          value={reference} onChange={(e) => setReference(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <input type="password" className="form-control form-control-user" placeholder="Password / Contraseña" name="pass"
                          value={pass} onChange={(e) => setPass(e.target.value)} />
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <input type="password" className="form-control form-control-user" placeholder="Repeat password / Repetir Contraseña" name="repeatPass"
                        value={passRepeat} onChange={(e) => setPassRepeat(e.target.value)}/>
                      </div>
                    </div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button type="submit" className="btn btn-primary btn-user btn-block"
                     disabled={isButtonDisabled} // Desactiva el botón si isButtonDisabled es true
                     >
                       {isLoading ? 'Loading...' : 'Register Account'} {/* Muestra "Loading..." si isLoading es true */}
                     
                    </button>
                  </form>
                  <hr />
                  <div className="text-center">
                    <a className="small" href="#forgot-password">Forgot Password? / ¿Olvidaste tu contraseña?</a>
                  </div>
                  <br/>
                  <div className="text-center">
                    <a className="small" href="#login">Already have an account? Login! / ¿Ya tienes cuenta? Iniciar Sesión!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;