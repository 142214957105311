import { Routes, Route } from 'react-router-dom';
import Login from "../components/Login.js";
import Register from "../components/Register.js";
import Forgot from '../components/Forgot.js';
import Profile from '../components/Profile.js';
import Kyc from '../components/Kyc.js';
import Settings from '../components/Settings.js';
import Paymentdata from '../components/Paymentdata.js';
import Dashboard from '../components/Dashboard/Dashboard.js';
import Rutaprotegida from "../components/Rutaprotegida.js";
import {useLocalStorage} from 'react-use';
import { useEffect, useState } from "react";
import { Navigate, Outlet } from 'react-router-dom';

import { useNavigate } from 'react-router-dom'; // Para redirigir

function AppRouter() {
    const [loggedIn, setLoggedIn] = useState(true);
    const [user, setUser] = useLocalStorage('user');
    const navigate = useNavigate();  // Hook de navegación

    const checkForInactivity = () => {
        const expireTime = localStorage.getItem("expireTime");
        if (expireTime && parseInt(expireTime, 10) < Date.now()) {
            console.log("Log out!");
            setLoggedIn(false);
            setUser(""); 
            localStorage.removeItem('user');
            localStorage.removeItem('expireTime');
            navigate('/login');  
        }
    };

    const updateExpireTime = () => {
        const expireTime = Date.now() + 15 * 60 * 1000; // 15 minutos
        localStorage.setItem("expireTime", expireTime);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        updateExpireTime();
        const events = ['click', 'keypress', 'scroll', 'mousemove'];
        events.forEach(event => window.addEventListener(event, updateExpireTime));

        return () => {
            events.forEach(event => window.removeEventListener(event, updateExpireTime));
        };
    }, []);

    // Si no está autenticado, redirigir
    if (!loggedIn) {
        return <Navigate to="/login" />;
    }

    return (
        <Routes>
            <Route exact path={'/'} element={<Login />} />
            <Route exact path={'/login'} element={<Login />} />
            <Route exact path={'/forgot-password'} element={<Forgot />} />
            <Route exact path={'/register'} element={<Register />} />
            <Route element={<Rutaprotegida canActivate={user} />}>
                <Route path={'/index'} element={<Dashboard />} />
                <Route path={'/profile'} element={<Profile />} />
                <Route path={'/kyc'} element={<Kyc />} />
                <Route path={'/settings'} element={<Settings />} />
                <Route path={'/payment-data'} element={<Paymentdata />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;




// function AppRouter() {
//     const [loggedIn, setLoggedIn] = useState(true);
//     const [user, setUser] = useLocalStorage('user');

//     const checkForInactivity = () => {
//         const expireTime = localStorage.getItem("expireTime");
//         if (expireTime && parseInt(expireTime, 10) < Date.now()) {
//             console.log("Log out!");
//             setLoggedIn(false);
//             setUser(""); 
//             localStorage.removeItem('user'); 
//             localStorage.removeItem('loggedIn'); 
//             window.location.reload();
//         }
//     };

//     const updateExpireTime = () => {
//         const expireTime = Date.now() + 15 * 60 * 1000;
//         localStorage.setItem("expireTime", expireTime);
//     };

//     useEffect(() => {
//         const interval = setInterval(() => {
//             checkForInactivity();
//         }, 1000);
//         return () => clearInterval(interval);
//     }, []);

//     useEffect(() => {
//         updateExpireTime();

//         const events = ['click', 'keypress', 'scroll', 'mousemove'];
//         events.forEach(event => window.addEventListener(event, updateExpireTime));

//         return () => {
//             events.forEach(event => window.removeEventListener(event, updateExpireTime));
//         };
//     }, []);
    
//     const isAuthenticated = !!user;


//     return (
//         <Routes>
//             <Route exact path={'/'} element={<Login/>} />
//             <Route exact path={'/login'} element={<Login/>} />
//             <Route exact path={'/forgot-password'} element={<Forgot/>} />
//             <Route exact path={'/register'} element={<Register/>} />
//             <Route element={<Rutaprotegida canActivate={isAuthenticated} />}>
//                 <Route path={'/index'} element={<Dashboard/>} />
//                 <Route path={'/profile'} element={<Profile/>} />
//                 <Route path={'/kyc'} element={<Kyc/>} />
//                 <Route path={'/settings'} element={<Settings/>} />
//                 <Route path={'/payment-data'} element={<Paymentdata/>} />
//             </Route>
//         </Routes>
//     );
// }

// export default AppRouter;
