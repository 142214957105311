import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {useLocalStorage} from 'react-use';
import { useTranslation } from 'react-i18next';
import { API2 } from '../../constants/api.js';
import { encodedCredentials51 } from '../../constants/credentials.js';
import { useApiDataContext } from '../../constants/apiContext.js';
// import useApiData from '../../constants/apiInicio.js';



const  Tabledavid = () => {
  const [t, i18n] = useTranslation("global");
  const fechaActual = new Date();
  const [type, setType] = useState("USD");
  const [user, setUser] = useLocalStorage("user");
  const [investment, setInvestment] = useState("");
  const [investmentTime, setInvestmentTime] = useState("");
  const [withdrawals, setWithdrawals] = useState("");
  const [selectedFileNamee, setSelectedFileNamee] = useState('Select file:');
  const { sesenta, veinte, divisa } = useApiDataContext();

  const calculateTotal = () => {
    let multiplier = 0;
    switch (investmentTime) {
      case "6":
        multiplier = veinte;
        break;
      case "12":
        multiplier = sesenta;
        break;
      default:
        multiplier = 0;
        break;
    }
    const total =(investment * multiplier) / 100;
    return total;
    
  };
  const calculateMonthlyProfit = () => {
    let monthlyMultiplier = 0;
    switch (investmentTime) {
      case "6":
        monthlyMultiplier = veinte;
        break;
      case "12":
        monthlyMultiplier = sesenta;
        break;
      default:
        monthlyMultiplier = 0;
        break;
    }
    const monthlyProfit =( investment * monthlyMultiplier) / 100 / 12;
    return monthlyProfit;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const formData = new FormData(); 
    const data = {
      "user": user,
      "financialInvestment": investment,
      "investmentType" : type,
      "investmentTime": investmentTime,
      "withdrawalsTime": withdrawals,
      "createDate": fechaActual,
      "userModify": "pagina web",
    };
    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];
    
    if (!file) {
      console.error('Selecciona un archivo para subir.');
      return;
    }

    
    formData.append('file', file, file.name);
    formData.append('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));

   {
    const response = await axios.post(`${API2}investment`, formData, {
      headers: {
        'Authorization': `Basic ${encodedCredentials51}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      window.location.reload();
      // console.log(data);
        Swal.fire({
          title: 'Success',
          text: 'The operation was successful.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      })
      .catch((error) => {
        console.error('Error:', error.message);
        Swal.fire({
          title: 'Error',
          text: error.message || 'There was an error processing the request.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
  };
  }
  const handleInvestmentChange = (e) => {
    setInvestment(parseFloat(e.target.value));
  };

  const handleInvestmentTimeChange = (e) => {
    setInvestmentTime(e.target.value);
    const selectedValue = e.target.value;
    setInvestmentTime(selectedValue);
    if (selectedValue !== '12 months') {
      setWithdrawals(selectedValue);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileNamee("Selected file: " + file.name);
  };


  return (
    <div className="font-weight-bold text-gray-800 pb-2">
      {/* {validate === 1 ? null : */}
       <form className="user"  onSubmit={handleSubmit}> 
      <div className="row">
        <div className="col-md-3 col-xs-12">
        <div action="#" className="d-flex flex-column">
          <label className="mb-2" >{t("header.investment2")}  $ </label>
          </div><input type="number" step="1000" min="1000" className="form-control" name="number"
          value={investment}  onChange={handleInvestmentChange} />
        </div>
        <div className="col-md-3 col-xs-12">
        <div action="#" className="d-flex flex-column">
          <label className="mb-2">{t("header.type")}</label>
          <select name='type' required className="form-control"
          value={type} onChange={(e) => setType(e.target.value)} >
           
            {divisa === 'MXN' ? ( <> <option value="MXN">MXN</option> <option value="" disabled>USD</option> </> )
             : divisa === 'USD' ? ( <> <option value="USD">USD</option> <option value="" disabled>MXN</option>  </> ) 
              : ( <> 
                      <option value="USD">USD</option>
                      <option value="MXN">MXN</option>  
                  </> ) } 

          </select>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div action="#" className="d-flex flex-column">
            <label htmlFor="lang1" className="mb-2">{t("header.investment-period")}</label>
            <select name="period" required id="lang1" className="form-control"
            value={investmentTime} onChange={handleInvestmentTimeChange}>
              <option value="" disabled >{t("header.select")}</option>
              {/* <option value="3">{t("header.3months")}</option> */}
              <option value="6">{t("header.6months")}</option>
              <option value="12">{t("header.12months")}</option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div action="#" className="d-flex flex-column">
            <label htmlFor="lang2" className="mb-2">{t("header.payout-time")}</label>
            <select name="time" required id="lang2" className="form-control"
             value={withdrawals} onChange={(e) => setWithdrawals(e.target.value)}>
               <option value="" disabled>{t("header.select")}</option>
              {investmentTime === '12' ? (
                <>
                  <option value="1">{t("header.1month")}</option>
                  <option value="3">{t("header.3months")}</option>
                  <option value="6">{t("header.6months")}</option>
                  <option value="12">{t("header.12months")}</option>
                </>
              ) : (
                <option value={withdrawals}>{withdrawals} </option>
              )}
            </select>
          </div> <br />
        </div>
        <br />
        <div className="col-md-6 col-xs-12">
        {t("header.payment")}: <br /> 
        <label htmlFor="fileInput" className="btn btn-primary btn-block">
      <input type="file" id="fileInput" required className="d-none" onChange={handleFileChange}/>
      {selectedFileNamee && <span>{selectedFileNamee}</span>}
      </label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <h5 className="font-weight-bold" >{t("header.repayment")}  <br /> ${investment.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
        </div>
        <br />
        <div className="col-md-4 col-xs-12">
        {t("header.earnings-monthly")}
          <h5 className="font-weight-bold text-warning"> ${calculateMonthlyProfit().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
          
        </div>
        <div className="col-md-4 col-xs-12">
          <h5 className="font-weight-bold text-warning">{t("header.earnings")}  <br /> ${calculateTotal().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
        </div>
      </div>
      
      <div className="col">
        <button type="submit" className="btn btn-sm btn-primary shadow-sm float-right">{t("header.accept")}</button>
      </div>
      </form>
    </div>
  );
};

export default Tabledavid;